@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    height: 100%;
    --duration: 0.45s;
    --cubic: cubic-bezier(0.4, 0, 0.2, 1);
    --color-1: #000;
    --color-2: #51d5c2;
}
body {
    min-height: 100vh;
    display: flex;
    background-color: var(--background-color);
}
a.button {
    display: inline;
    width: 100px !important;
    height: 30px !important;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 5px;
    background: var(--primary-color);
    color: var(--button-text-color) !important;
}
button.button {
    display: inline;
    height: 30px !important;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 5px;
    background: var(--primary-color);
    color: var(--button-text-color) !important;
}
/* Common Tags */
.MuiDataGrid-root {
    font-family: inherit !important;
}
.MuiDataGrid-row {
    cursor: pointer;
    &.inactiveSubscription {
        opacity: 0.7;
        cursor: pointer;
        .subStatus {
            background: red;
            padding: 5px;
            border-radius: 5px;
            color: #fff;
            height: 10px;
            width: 10px;
            display: inline-block;
            margin-right: 5px;
        }
    }
    &.activeSubscription {
        opacity: 1;
        .subStatus {
            background: green;
            padding: 5px;
            border-radius: 5px;
            color: #fff;
            height: 10px;
            width: 10px;
            display: inline-block;
            margin-right: 5px;
        }
    }
}
.MuiDataGrid-row,
.MuiDataGrid-columnHeader {
    input {
        pointer-events: all !important;
    }
    height: auto !important;
}
.MuiDataGrid-cellContent,
.MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-columnSeparator--resizable,
.MuiDataGrid-menuIconButton {
    // white-space: break-spaces !important;
    svg {
        color: var(--button-text-color) !important;
    }
    line-height: 20px;
    font-size: 0.9rem;
    color: var(--button-text-color) !important;
}
.MuiDataGrid-columnHeaders {
    background: var(--primary-color) !important;
    color: var(--button-text-color);
    min-height: 40px !important;
    height: 40px !important;
    .upcomingExpirations,
    .upcomingExpirationsExpanded {
        background: #000;
    }
}
.MuiDataGrid-container--top [role="row"] {
    background: var(--primary-color) !important;
    height: 40px !important;
}
.MuiDataGrid-cellContent,
.MuiSvgIcon-root,
.MuiInputBase-inputTypeSearch,
.MuiInput-input {
    color: var(--primary-text-color) !important;
    &:disabled {
        background: var(--input-color);
    }
}
.MuiIconButton-root {
    padding-top: 0px !important;
    &:hover {
        background-color: rgba(0, 0, 0, 0) !important;
    }
}
.MuiAvatar-square {
    background-color: var(--navbar-color) !important;
    align-items: normal !important;
}
.MuiSvgIcon-root {
    color: var(--primary-text-color) !important;
}
.MuiDataGrid-cell {
    color: var(--primary-text-color) !important;
    line-height: inherit !important;
    overflow: hidden !important;
    height: auto;
    display: flex !important;
    align-items: center;
    a,
    button {
        padding: 5px;
        border-radius: 5px;
        margin-right: 5px;
        background: var(--primary-color);
        color: var(--button-text-color) !important;
    }
}
.MuiPopper-root {
    position: absolute !important;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(199px, 116px, 0px);
    z-index: 10000;
}
.MuiDataGrid-footerContainer {
    p {
        margin-bottom: 0px;
    }
}
.MuiSelect-select,
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
    color: var(--primary-text-color) !important;
}
.MuiDataGrid-overlay {
    background: var(--card-color) !important;
    color: var(--primary-text-color) !important;
}
.MuiAccordion-root {
    box-shadow: none !important;
    &::before {
        height: 0px !important;
    }
}
.MuiAccordionSummary-root,
.MuiAccordionDetails-root {
    padding: 0 !important;
}
a,
button {
    color: var(--primary-text-color);
    &:hover {
        color: var(--primary-text-color) !important;
    }
    &.colorLink {
        color: var(--primary-color);
        &:hover {
            color: var(--primary-color) !important;
        }
    }
}
p {
    color: var(--primary-text-color);
    &.updateNote {
     color: red;
    }
}
b,
.bold {
    font-weight: bold !important;
}
hr {
    border: 5px solid var(--border-color) !important;
    &.small {
        border: 2px solid var(--border-color) !important;
    }
}
pre {
    font-family: inherit !important;
    font-size: 100%;
}
.blurred {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}
.__json-pretty__ {
    line-height: 1.3;
    background: var(--modal-background-color);
    overflow: auto;
}
.__json-key__ {
    font-weight: 800 !important;
}
.centerText {
    text-align: center !important;
}
.table {
    td {
        color: var(--primary-text-color);
    }
    tr {
        border-top: 1px solid var(--border-color) !important;
        border-bottom: none !important;
    }
    a {
        padding: 5px;
        border-radius: 5px;
        margin-right: 5px;
        background: var(--primary-color);
        color: var(--button-text-color) !important;
        &.noButton {
            text-decoration: underline;
            background: var(--button-text-color);
            color: var(--primary-color) !important;
        }
    }
}
button.btn-danger,
a.btn-default {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}
.lineBreakAnywhere {
    line-break: anywhere;
}
#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
}
p {
    &.disclaimer {
        font-size: 12px !important;
    }
}
.disabled {
    opacity: 0.5;
}
.error-message {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
span {
    &.currencySymbol {
        position: absolute;
        margin-left: 5px;
        margin-top: 7px;
    }
}

.modal-content .modal-body {
    .displayPreview {
        zoom: 65%;
        .allVenues {
            min-height: 136px !important;
        }
        .subscriberUIDashboard {
            .card,
            .support {
                min-height: 230px !important;
                .card-body {
                    h4 {
                        color: rgb(187, 187, 187) !important;
                    }
                }
            }
        }
        .collapse:not(.show) {
            display: none !important;
        }
        .nav-item {
            max-height: inherit !important;
        }
        .navbar-toggler-icon2 {
            width: 1.5em;
            height: 1.5em;
            vertical-align: middle;
            content: "";
            background: no-repeat 50%;
            background-size: 100% 100%;
            display: inline-block;
        }
        .navbar-nav {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            .nav-link {
                font-size: inherit !important;
            }
        }
        .navbar-collapse {
            display: block !important;
        }
        .card,
        .support {
            min-height: auto !important;
        }
        .navbar-toggler {
            display: block !important;
        }
        .react-toggle-thumbDisplayOptionsNetwork,
        .react-toggle-thumbDisplayOptionsVenue,
        .react-toggle-thumbDisplayOptionsSubscriber {
            position: absolute;
            top: 1px;
            left: 1px;
            width: 22px;
            height: 22px;
            border: 1px solid #4d4d4d;
            border-radius: 50%;
            background-color: #fafafa;
            box-sizing: border-box;
            transition: all 0.25s ease;
        }
        .dropdown-menu {
            transform: none !important;
        }
        .card-body{
         padding: 15px;
        }
        .select-dropdown{
         padding-left: 0px !important;
        }
        .grid{
         p{
          padding: 20px 1rem;
         }
         .quickSearch{
          padding-top: 0px
         }
        }
        .upcomingExpirations{
         .MuiDataGrid-container--top [role=row]{
           background: #000 !important;
         }
        }
        .recentResidents,
        .allVenues{
         .MuiDataGrid-container--top [role=row]{
          background: var(--new-primary-color) !important;
        }
        }
        .support {
         padding: 10px;
         min-height: 170px;
     }
    }
}
.refundAmount {
    padding-left: 15px;
}
.borderBox {
    border: 1px solid var(--border-color) !important;
}
.borderBoxRounded {
    border: 1px solid var(--border-color) !important;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}
.fa-lock,
.fa-unlock {
    &:hover {
        cursor: pointer;
    }
}
.fa-question-circle {
    margin-left: 5px;
    color: var(--primary-text-color);
}
.rightTooltip {
    left: auto !important;
    display: inline-block !important;
    margin-left: 10px;
    z-index: 100 !important;
    .tooltip-inner {
        color: #fff !important;
        z-index: 1000 !important;
        text-align: left !important;
        max-width: 300px !important;
        min-width: 250px !important;
    }
}
.md-inner-main {
    background: rgb(110, 110, 110) !important;
}
.dash:after {
    display: block;
    text-align: center;
    font-size: 1.3em;
    position: absolute;
    color: #000;
    content: "—";
    top: 5px;
    left: -8px;
}
.menu {
    margin: 0;
    display: flex;
    user-select: none;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #fefefe;
    -webkit-tap-highlight-color: transparent;
}
@media (max-width: 42.625em) {
    .menu {
        font-size: 0.55em;
    }
}
.menu__item {
    all: unset;
    flex-grow: 1;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    padding-top: 0.5em;
    position: relative;
    align-items: center;
    color: var(--color-1);
    justify-content: center;
    transition: flex-grow var(--duration) var(--cubic);
    padding: 5px;
    border-bottom: 1px solid var(--border-color);
}
.menu__icon {
    font-size: 1.05em;
    stroke: currentColor;
    transition: transform var(--duration) var(--cubic);
    a.nav-link {
        padding: 0 !important;
        font-weight: normal;
    }
}
.menu__text {
    left: 4.15em;
    text-transform: capitalize;
    letter-spacing: 0.01em;
    transform: translate3d(0, 109%, 0);
    transition: transform calc(var(--duration) / 3.7);
    font-size: 0;
}
.menu__item.active {
    max-height: 37px;
    flex-grow: 2.7;
    // border-bottom: 1px solid var(--border-color);
    border: 1px solid var(--border-color);
    border-bottom-color: var(--modal-background-color);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: var(--primary-color) !important;
    background-color: var(--navbar-tab-color) !important;
    border: 1px solid var(--border-color);
    border-bottom: 1px solid var(--modal-background-color);
}
.menu__item.active .menu__icon {
    transform: translate3d(-95%, 0, 0);
}
.menu__item.active::before {
    transform: scale(1);
}
.menu__item.active::after {
    transform: translate3d(6.3em, 0, 0) scaleX(1);
    transition: transform var(--duration) var(--cubic);
}
.menu__text.active {
    transform: translate3d(0, 0, 0);
    transition: transform calc(var(--duration) / 1.5);
    color: var(--primary-color) !important;
    font-size: inherit;
}
/*Login*/
.Logo {
    img {
        width: 90px;
    }
}
.FavIcon {
 img{
  width: 25px;
 }
}
.Login {
    background-repeat: no-repeat;
    background-position: right !important;
    background-size: contain;
    background-color: var(--login-background-color);
    padding: 60px 20px;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    &.LoginDish {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh !important;
        min-height: 750px !important;
        .loginFormContainer {
            padding-bottom: 60px;
        }
    }
    .loginImage {
        position: absolute;
        width: 45%;
        height: 100%;
        right: 0px;
        top: 0px;
        background-size: cover;
        background-position: center top;
    }
    p {
        color: var(--login-text-color);
        font-size: 20px;
    }
    .Logo {
        img {
            width: 280px;
            padding-bottom: 60px;
        }
        .logoContainer {
            padding: 0;
            .vaultLogo {
                align-items: flex-end;
                justify-content: flex-end;
                display: flex;
                img {
                    width: 150px;
                }
            }
        }
    }
    .loginFormContainer {
        .underlineLinks {
            a {
                text-decoration: underline;
                font-weight: bold;
            }
            p {
                font-size: 1rem;
            }
        }
    }
    .loginForm {
        flex-grow: 1;
        flex-shrink: 0;
        h2 {
            color: var(--login-text-color);
            font-weight: bold;
            margin-bottom: 20px;
        }
        p {
            color: var(--login-text-color);
            font-size: 20px;
        }
        a {
            color: var(--login-text-color);
            &:hover {
                color: var(--login-text-color) !important;
            }
        }
        button {
            width: 30%;
            float: right;
        }
        .loginDisclosure {
            a {
                text-decoration: underline;
            }
            p {
                font-size: 1rem;
            }
        }
    }

    .loginFormContainer,
    .FormContainer {
        padding: 40px 15px;
        .loginDisclosure {
            a {
                color: var(--input-focus-color) !important;
                &:hover {
                    color: var(--login-text-color) !important;
                }
            }
        }
        label {
            color: var(--login-text-color) !important;
        }
    }
    .logoMobile {
        display: none;
    }
    .password {
        position: relative;
        span {
            position: absolute;
            right: 10px;
            top: 11px;
        }
    }
    .passwordReset {
        position: relative;
        span {
            position: absolute;
            right: 10px;
            top: 40px;
        }
    }
    .forgotRemember {
        margin: 0px !important;
        padding: 0px 0px 20px !important;
    }
    .rememberMe {
        text-align: right;
        color: var(--primary-text-color);
        .form-check {
            float: right;
        }
    }
    .bottomBanner {
        position: absolute;
        bottom: 30px;
        height: 35px;
        width: 100%;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(240, 20, 70, 1) 50%,
            rgba(0, 0, 0, 0) 100%
        );
        max-width: 55%;
        left: 0;
        h2 {
            text-align: center;
            a {
                color: var(--login-text-color) !important;
            }
        }
    }
    .topBanner {
        background: linear-gradient(
            90deg,
            var(--banner-color-1),
            var(--banner-color-2)
        );
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        width: 100%;
        left: 0;
        h2 {
            text-align: center;
            color: var(--button-text-color);
        }
        a {
            color: #fff;
        }
    }
    .Form {
        padding: 0;
    }
    &.noPadding {
        padding: 0;
    }
    .fa-user {
        margin-top: 0px !important;
        &:before {
            left: 7px !important;
        }
    }
    .fa-user,
    .fa-check {
        right: 70px;
        border-radius: 15px;
        width: 25px;
        height: 25px;
        top: 2px;
        font-size: 14px;
        margin-right: 10px;
        background: var(--primary-color);
        &:before {
            top: 5px;
            position: relative;
            left: 7px;
            color: #fff;
        }
    }
}
p#passwordDisclaimer {
    display: none;
    font-size: 20px !important;
    color: red;
}
.loginIcon {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 15px;
}
.AUPTitle {
    h4 {
        width: 100%;
        color: #000;
    }
    .modal-title {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .Logo {
        width: 120px;
        h4 {
            width: 100%;
            color: #000;
        }
        img {
            max-width: 100%;
            max-height: 100px;
            display: block;
            padding-bottom: 0px;
        }
    }
    .AUPHeaderTitle {
        position: absolute;
        text-align: center;
        width: 95%;
        h4 {
            text-align: center !important;
            float: none !important;
        }
    }
}
.loginModal {
    .loginIcon {
        margin-bottom: 0;
    }
    .fa-user,
    .fa-check {
        right: 70px;
        border-radius: 15px;
        width: 25px;
        height: 25px;
        top: 2px;
        font-size: 14px;
        margin-right: 10px;
        background: var(--primary-color);
        &:before {
            top: 5px;
            position: relative;
            left: 7px;
            color: #fff;
        }
    }
}
.show {
    > .btn-primary.dropdown-toggle {
        background-color: var(--primary-color) !important;
        box-shadow: 0 5px 11px 0 rgb(0, 0, 0), 0 4px 15px 0 rgb(0, 0, 0);
        border-color: var(--primary-color) !important;
    }
}
.faq {
    a {
        color: var(--primary-color);
    }
}
/* Calendar for Add/Modify Resident and Guests*/
.ui.top.popup {
    &::before {
        background: var(--input-color);
    }
}
.ui.table {
    border: var(--border-color) td, th {
        border-top: 1px solid var(--border-color) !important;
    }
    background: var(--modal-background-color) !important;
}
.ui.table thead th {
    background: var(--input-color);
    color: var(--primary-text-color);
}
.ui.table tr td.disabled,
.ui.table tr.disabled td,
.ui.table tr.disabled:hover,
.ui.table tr:hover td.disabled {
    color: var(--secondary-color);
}
/* Search on Venue Management and Network Management Dashboards */
.ui {
    .category.search > .results .category.results {
        background: var(--input-color);
    }
}
.ui.search > .results .result .title,
.ui.search > .results .result .description {
    color: var(--primary-text-color) !important;
}
.ui.category.search > .results .category .result:hover,
.ui.search > .results .result:hover {
    background: var(--primary-color) !important;
    color: #fff !important;
}
.ui.category.search > .results .category .results {
    background: var(--input-color);
    border: 1px solid var(--border-color) !important;
    color: var(--primary-text-color) !important;
    &:hover {
        background: var(--background-color) !important;
        color: #fff !important;
    }
}
/* Modal Content */
.apexcharts-tooltip {
    background: var(--modal-background-color) !important;
}
.modal-content {
    background-color: var(--modal-background-color) !important;
    h4,
    h5,
    span,
    strong,
    label,
    text,
    .dropdown-toggle:after,
    pre {
        color: var(--primary-text-color) !important;
    }
    .password_subscriber {
        background-color: var(--background-color) !important;
    }
    text {
        fill: var(--primary-text-color) !important;
    }
    .mdb-dataTable {
        tbody {
            background-color: var(--input-color) !important;
        }
    }
    .modal-header {
        border-bottom: 1px solid var(--border-color) !important;
        &.error {
            background: #cc0000;
            border-radius: 10px 10px 0px 0px;
            h4 {
                color: #fff !important;
            }
            i {
                margin-right: 5px;
                font-size: 18px;
            }
        }
    }
    .modal-body {
        h2 {
            color: var(--primary-text-color);
        }
        &.error {
            text-align: center;
            h4 {
                margin: 20px 0px;
            }
        }
        .required {
            font-weight: bold;
            border: 2px solid #cc0000;
        }
        p {
            word-wrap: anywhere;
        }
    }
    .select-box {
        width: 100%;
        overflow: hidden;
        border: 1px solid var(--border-color);
        position: relative;
        border-radius: 0.25rem;
        &select {
            &:disabled {
                background: var(--input-color) !important;
            }
        }
    }
    .select-box:after {
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 6px solid var(--primary-text-color);
        position: absolute;
        top: 40%;
        right: 5px;
        content: "";
        z-index: 98;
    }
    .select-box select {
        border: 0;
        position: relative;
        z-index: 99;
        background: none;
        color: var(--primary-text-color);
        -webkit-appearance: none;
        appearance: none;
        &:disabled {
            background: var(--input-color) !important;
        }
    }
    .custom-select {
        background: var(--primary-color-rgb);
        &:disabled {
            background: var(--input-color);
        }
        &select::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: calc(50% - 9px);
            right: 19px;
            width: 11px;
            height: 11px;
        }
    }
    /* tinyMCE textbox */
    .tox {
        .tox-menubar,
        .tox-toolbar__primary {
            background: var(--input-color);
            border-bottom: 1px solid #ccc;
        }
        .tox-tbtn svg {
            fill: var(--primary-text-color) !important;
        }
        .tox-tbtn--disabled svg {
            fill: var(--primary-text-color) !important;
        }
    }
    .select_box::after {
        transform: rotate(45deg);
        border-right: 1px solid var(--navbar-text-color);
        border-bottom: 1px solid var(--navbar-text-color);
        pointer-events: none;
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 27px;
        width: 11px;
        height: 11px;
    }
    .updatePayment {
        text-align: center;
        h2 {
            font-size: 18px;
            color: var(--primary-color) !important;
            margin-bottom: 20px;
        }
        p {
            font-size: 18px;
        }
        button.button-danger {
            min-height: 44px;
            margin: 0.375rem;
            color: inherit;
            text-transform: uppercase;
            word-wrap: break-word;
            white-space: normal;
            cursor: pointer;
            border: 0;
            border-radius: 0.125rem;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
                0 2px 10px 0 rgba(0, 0, 0, 0.12);
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            padding: 0.84rem 2.14rem;
            font-size: 0.81rem;
            line-height: 1.5;
            background: var(--primary-color);
            color: var(--button-text-color) !important;
            a {
                color: var(--button-text-color) !important;
            }
        }
        h4 {
            font-size: 18px;
            color: var(--primary-color) !important;
            margin-bottom: 20px;
        }
    }
    .noBorder {
        border-bottom: none !important;
    }
}
/* Dropdown menus for selecting Venue */
.dropdown-menu {
    background-color: var(--navbar-color) !important;
    button {
        color: var(--navbar-text-color) !important;
        a {
            color: var(--navbar-text-color) !important;
        }
    }
}
.breakText {
    line-break: anywhere;
}
/* FAQ */
.faqModal {
    padding: 20px !important;
    .faq {
        border: 1px solid #ccc;
        max-height: 500px;
        overflow: auto;
        margin-bottom: 5px;
        padding: 20px;
        border: 1px solid #ccc;
        max-height: 500px;
        overflow: auto;
    }
    .input {
        width: 40%;
        margin-bottom: 20px;
    }
    .ui.icon.input {
        > i.icon {
            left: 0;
        }
        > input {
            padding-left: 2.67142857em !important;
        }
    }
}
/*Dark/Light Mode Toggle*/
.react-toggle-track {
    background-color: #000000;
    i {
        color: var(--button-text-color);
    }
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
    i {
        color: var(--button-text-color);
    }
}
.react-toggle--checked .react-toggle-track {
    background-color: var(--primary-color);
    i {
        color: var(--button-text-color);
    }
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--primary-color);
}
/* Forms */
.form-control {
    input {
        background: var(--input-color);
        color: var(--primary-text-color);
        &:disabled {
            background: var(--input-color);
            filter: brightness(85%);
        }
    }
    background-color: var(--input-color);
    border-color: var(--border-color);
    color: var(--primary-text-color);
    &.PhoneInput {
        height: auto;
        margin-top: 0px;
        font-size: 1rem !important;
        .PhoneInputInput {
            &::placeholder {
                color: var(--primary-text-color);
                opacity: 0.3;
            }
        }
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem var(--primary-color-rgb) !important;
        color: var(--primary-text-color);
        border-color: var(--input-border-color) 1px solid;
        background: var(--input-color);
    }
    &::placeholder {
        color: var(--primary-text-color);
        opacity: 0.3;
    }
    &.PhoneInput--focus {
        box-shadow: 0 0 0 0.2rem var(--primary-color-rgb) !important;
    }
    &:focus-visible {
        outline-color: var(--primary-color-rgb) !important;
    }
    &:disabled {
        background-color: var(--input-color);
        filter: brightness(85%);
    }
    .dateTimePicker {
        &:focus {
            border: var(--border-color) !important;
        }
        input {
            &:focus {
                box-shadow: 0 0 0 0.2rem var(--primary-color-rgb) !important;
            }
        }
    }
    &.centerPlaceholder {
        &::placeholder {
            text-align: center;
        }
    }
}
.disabledArea {
    background-color: var(--input-color);
    padding: 0.375rem 0.75rem;
    border-color: var(--border-color);
    color: var(--primary-text-color);
    font-size: 16px;
}
#selectTrialCycles {
    width: 275px;
}
input {
    &::placeholder {
        color: var(--primary-text-color) !important;
    }
    &#shoppingCartPriceAdjustment {
        width: 275px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='30px' width='85px'><text x='10' y='22' fill='gray' font-size='14' font-family='arial'>$</text></svg>");
        padding-left: 20px;
        background-repeat: no-repeat;
        &.subscriptionDiscount {
            width: 100% !important;
        }
    }
    &#shoppingCartUnitPriceAdjustment {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='30px' width='85px'><text x='2' y='22' fill='gray' font-size='14' font-family='arial'>$</text></svg>");
        padding-left: 12px;
        background-repeat: no-repeat;
    }
    &#realpageSiteID,
    &#realpagePMCID,
    &#realpageSyncDate {
        width: 375px;
    }
}
.ui.icon.input > i.icon {
    &::before {
        color: var(--primary-text-color);
    }
}
/* Select Search */
/**
 * Main wrapper
 */
.select-search {
    position: relative;
    box-sizing: border-box;
}
#selectVenue.select-search {
    padding-bottom: 10px;
}
.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}
/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
    &::after {
        border-right: 1px solid var(--primary-text-color);
    }
}
.select-search__options {
    margin-bottom: 0px;
}
// .select-search__value::after {
//   content: "";
//   display: inline-block;
//   position: absolute;
//   top: calc(50% - 9px);
//   right: 19px;
//   width: 11px;
//   height: 11px;
// }
/**
 * Input
 */
.select-search__input,
.selectState select {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    outline: none;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    color: var(--primary-text-color) !important;
}
.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
    appearance: none;
}
.select-search__input:not([readonly]):focus {
    cursor: initial;
    border: 1px solid red;
    box-shadow: 0 0 0 0.2rem var(--primary-color-rgb) !important;
}
/**
 * Options wrapper
 */
.select-search__select {
    background: var(--input-color);
    color: var(--primary-text-color) !important;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}
/**
 * Options
 */
.select-search__options {
    list-style: none;
}
/**
 * Option row
 */
.select-search__row {
    white-space: pre-line;
    height: 46px;
}
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}
/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 46px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    background: var(--input-color);
    color: var(--primary-text-color) !important;
}
.select-search--multiple .select-search__option {
    height: 48px;
}
.select-search__option.is-selected {
    background: var(--primary-color) !important;
    color: var(--button-text-color) !important;
}
.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: var(--primary-color-rgb) !important;
}
.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: var(--primary-color-rgb) !important;
    color: var(--button-text-color) !important;
}
/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}
/**
 * States
 */
.select-search.is-disabled {
    background: var(--input-color) !important;
}
.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}
.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}
/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}
.select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid var(--primary-text-color);
    position: absolute;
    top: 45%;
    right: 10px;
    content: "";
    z-index: 98;
}
.select-search--multiple .select-search__input {
    cursor: initial;
}
.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}
.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}
.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 100;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}
.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}
.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
/* Navbar */
.dropdown {
    .dropdown-menu {
        .dropdown-item {
            &:active {
                background-color: var(--primary-color);
            }
            &:hover {
                background-color: var(--primary-color);
            }
        }
    }
}
.dropleft {
    .dropdown-menu {
        .dropdown-item {
            &:active {
                background-color: var(--primary-color);
            }
            &:hover {
                background-color: var(--primary-color);
            }
        }
    }
}
.dropright {
    .dropdown-menu {
        .dropdown-item {
            &:active {
                background-color: var(--primary-color);
            }
            &:hover {
                background-color: var(--primary-color);
            }
        }
    }
}
.dropup {
    .dropdown-menu {
        .dropdown-item {
            &:active {
                background-color: var(--primary-color);
            }
            &:hover {
                background-color: var(--primary-color);
            }
        }
    }
}
.nav-icon1 {
    span {
        background: var(--primary-color);
    }
}
/* Venue Management UI */
#venueManagement,
#networkManagement {
    .fa-copy {
        &:hover {
            cursor: pointer;
        }
    }
    .quickSearch {
        .ui.input > input {
            background-color: var(--input-color);
        }
    }
    .ui.input > input {
        background-color: transparent;
        color: var(--primary-text-color);
        border: 1px solid var(--border-color);
        &:focus {
            color: var(--primary-text-color);
        }
    }
    .md-form {
        input {
            margin: 0;
            border-bottom: var(--primary-color) !important;
            box-shadow: 0 1px 0 0 var(--primary-color) !important;
            &:focus {
                outline: none !important;
                border-color: var(--border-color) !important;
                box-shadow: 0 0 0 0.2rem var(--primary-color-rgb) !important;
            }
        }
        .prefix.active {
            color: var(--primary-color) !important;
        }
    }
    .select-wrapper.true {
        box-shadow: 0 0 0 0.2rem rgb(220, 53, 69);
        border-color: var(--border-color) !important;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .nav-tabs {
        border-bottom: 1px solid var(--border-color);
        .nav-link {
            padding: 5px 10px !important;
            &.active {
                max-height: 37px;
                color: var(--primary-color) !important;
                background-color: var(--navbar-tab-color) !important;
                border: 1px solid var(--border-color);
                border-bottom: 1px solid var(--modal-background-color);
            }
        }
        a {
            font-size: 14px !important;
            color: var(--primary-text-color) !important;
            .fa {
                color: var(--primary-text-color) !important;
                background: none;
                margin-right: 0px !important;
                max-width: 20px;
                &:before {
                    top: 0px !important;
                    color: var(--primary-text-color) !important;
                }
            }
            .active {
                color: var(--primary-color) !important;
                background-color: var(--background-color) !important;
            }
        }
    }
    .modifyStaff {
        a.btn-primary {
            max-height: 37px;
            padding-left: 30px !important;
            margin-top: 10px !important;
            &:hover {
                color: var(--primary-color) !important;
            }
        }
    }
    .nav-item {
        max-height: 37px;
        a {
            &:hover {
                color: var(--primary-color) !important;
            }
        }
    }
    .mainNavigation {
        .twelveth-step {
            a.nav-link {
                &:hover {
                    color: #fff important;
                }
            }
        }
        display: flex;
        align-items: flex-end;
        position: absolute;
        min-height: 94px;
        right: 0;
        bottom: 0;
        margin-bottom: 10px;
        a.nav-link {
            padding: 0.5rem 30px !important;
            font-size: 20px;
            font-weight: bold;
        }
        .modal-body {
            a.nav-link {
                padding: 5px 10px !important;
                font-weight: normal;
            }
        }
        .darkModeToggle {
            position: absolute;
            left: -55px;
            top: -2px;
        }
        .account .accountLogout a {
            padding: 0 10px !important;
            font-weight: 400;
            font-size: 12px;
        }
        .account .accountLogout .accountSwitch {
            padding: 0 !important;
        }
    }
    #selectVenue {
        margin: 0px !important;
        .select-dropdown {
            font-weight: 700;
            font-size: 20px !important;
            box-shadow: none !important;
            margin: 0;
            // padding-left: 7px;
            min-width: 150px;
            white-space: pre;
            line-height: inherit;
            color: var(--primary-color);
        }
        .dropdown-content {
            background: var(--input-color);
            color: var(--primary-text-color);
            li {
                > a {
                    display: block;
                    padding: 0.5rem;
                    font-size: 0.9rem;
                    color: #212529;
                    font-weight: 400;
                }
                > span {
                    display: block;
                    padding: 0.5rem;
                    font-size: 0.9rem;
                    color: #212529;
                    font-weight: 400;
                }
            }
            .active {
                span {
                    color: var(--active-text-color) !important;
                }
            }
            span {
                color: var(--primary-text-color) !important;
            }
        }
        .md-form {
            input {
                border-bottom: var(--primary-color) !important;
                box-shadow: 0 1px 0 0 var(--primary-color) !important;
            }
        }
        &.select-wrapper {
            ul {
                padding: 0.5rem;
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 0.25rem;
                width: 300px;
            }
            span.caret {
                position: absolute;
                top: 7px;
                right: 0;
                float: right;
                font-size: 0.63rem;
                color: var(--primary-text-color);
            }
        }
        &.md-form {
            .prefix.active {
                color: #000 !important;
            }
        }
    }
    #selectVenueResidentModal {
        margin: 0px auto 10px !important;
        .select-dropdown {
            font-weight: 700;
            font-size: 20px !important;
            box-shadow: none !important;
            margin: 0;
            padding-left: 8px;
            min-width: 150px;
            white-space: pre;
            line-height: inherit;
            color: var(--primary-text-color) !important;
        }
        .dropdown-content {
            background: var(--input-color);
            color: var(--primary-text-color);
            li {
                > a {
                    display: block;
                    padding: 0.5rem;
                    font-size: 0.9rem;
                    color: #212529;
                    font-weight: 400;
                }
                > span {
                    display: block;
                    padding: 0.5rem;
                    font-size: 0.9rem;
                    color: #212529;
                    font-weight: 400;
                }
            }
            .active {
                span {
                    color: var(--active-text-color) !important;
                }
            }
            span {
                color: var(--primary-text-color) !important;
            }
        }
        .md-form {
            input {
                border-bottom: var(--primary-color) !important;
                box-shadow: 0 1px 0 0 var(--primary-color) !important;
            }
        }
        &.select-wrapper {
            ul {
                padding: 0.5rem;
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 0.25rem;
                width: 300px;
            }
            span.caret {
                position: absolute;
                top: 7px;
                right: 0;
                font-size: 0.63rem;
                color: #000;
            }
        }
    }
    .mainDashboard {
        background: var(--background-color);
    }
    .card {
        min-height: 233px;
        margin-bottom: 15px;
        background-color: var(--card-color);
        h2 {
            color: var(--primary-text-color);
        }
    }
    .actionSummary {
        background-color: var(--secondary-color);
    }
    form {
        label {
            margin-top: 0.6rem;
        }
    }
    .custom-select {
        min-height: 36.5px;
    }
    .md-form {
        .active {
            color: var(--primary-color) !important;
        }
    }
    #button {
        .buttonRed {
            background-color: var(--primary-color) !important;
        }
    }
    .btn-danger {
        color: var(--button-text-color);
        background-color: var(--primary-color) !important;
    }
    .dropdown-toggle {
        span {
            color: var(--primary-color);
        }
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        .MuiAutocomplete-input {
            &:first-child {
                color: var(--primary-color);
            }
        }
    }
    .venueNameModal {
        margin: 0 auto;
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            .MuiAutocomplete-input {
                &:first-child {
                    color: var(--primary-color);
                }
            }
        }
    }
    .btn-primary {
        color: #fff;
        background-color: var(--primary-color) !important;
    }
    .quicklink {
        a,
        svg {
            color: var(--primary-color) !important;
            &:hover {
                color: var(--button-text-color) !important;
            }
        }
        p {
            color: var(--primary-text-color);
        }
        &:hover {
            background: var(--primary-color);
            color: var(--button-text-color);
            > a,
            svg {
                background: var(--primary-color);
                color: var(--button-text-color) !important;
            }
            p {
                background: var(--primary-color);
                color: var(--button-text-color);
            }
        }
    }
    .residentSummary {
        background: var(--primary-color);
        min-height: 170px;
    }
    .subscribersTable {
        table {
            tbody {
                tr {
                    &.inactiveSubscription {
                        opacity: 0.7;
                        cursor: default;
                    }
                    &.activeSubscription {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .recentResidents,
    .subscribersTable {
        .mdb-dataTable-head {
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
        .justify-content-end {
            display: none !important;
        }
    }
    .upcomingExpirations {
        a.expirationAction {
            background: var(--primary-color);
        }
    }
    .recentResidentsViewModify {
        .mdb-dataTable-head {
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
    .modifyStaff {
        .mdb-dataTable-head {
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
    .supportTickets {
        .mdb-dataTable-head {
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
    .managementModalButton {
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        width: 65%;
        margin: 0 auto;
        min-height: 120px;
        margin-bottom: 20px;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        &:hover {
            color: var(--button-text-color) !important;
            > p {
                background: var(--primary-color);
            }
            background: var(--primary-color);
            > .csv-reader-input {
                label {
                    background: var(--primary-color);
                }
            }
        }
    }
    .newTicketModalButtonSelected {
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
    }
    .newTicketModalButton {
        border: 1px solid var(--primary-color);
        &:hover {
            > p {
                background: var(--primary-color);
            }
            background: var(--primary-color);
            > .csv-reader-input {
                label {
                    background: var(--primary-color);
                }
            }
        }
    }
    .newTicketModalButtonSelectedSM {
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
    }
    .newTicketModalButtonSM {
        border: 1px solid var(--primary-color);
        &:hover {
            > p {
                background: var(--primary-color);
            }
            background: var(--primary-color);
            > .csv-reader-input {
                label {
                    background: var(--primary-color);
                }
            }
        }
    }
    .stepperSelected {
        background-color: var(--primary-color);
    }
    .navbar {
        background: var(--navbar-color);
    }
    .navbar.navbar-dark {
        .breadcrumb {
            .nav-item {
                .nav-link {
                    color: var(--navbar-text-color);
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: var(--navbar-text-color);
                }
            }
        }
        form {
            .md-form {
                .form-control {
                    color: var(--navbar-text-color);
                }
            }
        }
    }
    .address {
        p {
            color: var(--navbar-text-color);
        }
    }
    .account {
        .accountLogout {
            .fa {
                color: var(--navbar-text-color);
            }
            .select {
                display: none;
            }
        }
    }
    .preloader-wrapper .spinner-blue,
    .preloader-wrapper .spinner-blue-only {
        border-color: var(--primary-color) !important;
    }
    #selectUnit {
        margin: 0 !important;
        padding: 0 !important;
        ul {
            padding-top: 0.5rem;
        }
        label {
            top: -17px;
        }
        .unit-select__control--is-focused {
            border: none !important;
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
        }
        .dropdown-content {
            li {
                > a {
                    color: #000;
                }
                > span {
                    color: #000;
                }
            }
            left: 0;
        }
        &.md-form {
            input.selectInput {
                border-bottom: none !important;
                box-shadow: none !important;
                line-height: inherit;
                margin: 0;
                padding: 3px 0px 4px 4px;
                width: 99%;
                &:focus {
                    border: none !important;
                    outline: none !important;
                    box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
                }
            }
        }
        &.form-control.is-invalid {
            background-position: right 20px center;
        }
        &.form-control.is-valid {
            background-position: right 20px center;
        }
    }
    #selectBuilding {
        margin: 0 !important;
        padding: 0 !important;
        ul {
            padding-top: 0.5rem;
        }
        label {
            top: -17px;
        }
        .building-select__control--is-focused {
            border: none !important;
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
        }
        .dropdown-content {
            li {
                > a {
                    color: #000;
                }
                > span {
                    color: #000;
                }
            }
            left: 0;
        }
        &.md-form {
            input.selectInput {
                border-bottom: none !important;
                box-shadow: none !important;
                line-height: inherit;
                margin: 0;
                padding: 3px 0px 4px 4px;
                width: 99%;
                &:focus {
                    border: none !important;
                    outline: none !important;
                    box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
                }
            }
        }
        &.form-control.is-invalid {
            background-position: right 20px center;
        }
        &.form-control.is-valid {
            background-position: right 20px center;
        }
    }
    #selectFloor {
        margin: 0 !important;
        padding: 0 !important;
        ul {
            padding-top: 0.5rem;
        }
        label {
            top: -17px;
        }
        .floor-select__control--is-focused {
            border: none !important;
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
        }
        .dropdown-content {
            li {
                > a {
                    color: #000;
                }
                > span {
                    color: #000;
                }
            }
            left: 0;
        }
        &.md-form {
            input.selectInput {
                border-bottom: none !important;
                box-shadow: none !important;
                line-height: inherit;
                margin: 0;
                padding: 3px 0px 4px 4px;
                width: 99%;
                &:focus {
                    border: none !important;
                    outline: none !important;
                    box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
                }
            }
        }
    }
    #selectLanguage {
        margin: 0 !important;
        padding: 0 !important;
        ul {
            padding-top: 0.5rem;
        }
        label {
            top: -17px;
        }
        .language-select__control--is-focused {
            border: none !important;
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
        }
        .dropdown-content {
            li {
                > a {
                    color: #000;
                }
                > span {
                    color: #000;
                }
            }
            left: 0;
        }
        &.md-form {
            input.selectInput {
                border-bottom: none !important;
                box-shadow: none !important;
                line-height: inherit;
                margin: 0;
                padding: 3px 0px 4px 4px;
                width: 99%;
                &:focus {
                    border: none !important;
                    outline: none !important;
                    box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
                }
            }
        }
        &.form-control.is-invalid {
            background-position: right 20px center;
        }
        &.form-control.is-valid {
            background-position: right 20px center;
        }
    }
    #selectServicePlan {
        margin: 0 !important;
        padding: 0 !important;
        ul {
            padding-top: 0.5rem;
        }
        label {
            top: -17px;
        }
        .serviceplan-select__control--is-focused {
            border: none !important;
            outline: none !important;
            box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
        }
        .dropdown-content {
            li {
                > a {
                    color: #000;
                }
                > span {
                    color: #000;
                }
            }
            left: 0;
        }
        .md-form {
            input.selectInput {
                border-bottom: none !important;
                box-shadow: none !important;
                line-height: inherit;
                margin: 0;
                padding: 3px 0px 4px 4px;
                width: 99%;
                &:focus {
                    border: none !important;
                    outline: none !important;
                    box-shadow: 0 0 0 0.2rem rgb(220, 53, 69) !important;
                }
            }
        }
        &.form-control.is-invalid {
            background-position: right 20px center;
        }
        &.form-control.is-valid {
            background-position: right 20px center;
        }
    }
    .form-control.PhoneInput--focus {
        border: none !important;
        outline: none !important;
    }
    .form-control {
        &:focus {
            border: none !important;
            outline: none !important;
        }
    }
    .dateTimePicker {
        input {
            &:focus {
                outline: none !important;
            }
        }
    }
    .venueName {
        min-height: 25px;
        margin: 20px 0px 0px 0px !important;
    }
    .logList {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .steps-form-2 .steps-row-2 .steps-step-2,
    .steps-form .steps-row .steps-step {
        min-width: 70px;
        .btn-circle-2,
        .btn-circle {
            width: 20px;
            height: 20px;
            border: 2px solid #59698d;
            background-color: white;
            color: #59698d;
            border-radius: 50%;
            padding: 15px;
            margin-top: -1px;
        }
        .btn-default {
            background-color: white !important;
        }
        &.disabled {
            opacity: 1 !important;
        }
        .fa {
            font-size: 16px;
            margin: auto;
            top: 7px;
            right: 0;
            bottom: 0px;
            left: 1px;
            position: absolute;
            font-family: inherit;
        }
    }
    .stepActive {
        .btn-circle-2,
        .btn-circle {
            background-color: #59698d !important;
            color: #fff !important;
        }
        p {
            font-weight: bold;
        }
    }
}
.form-control.is-invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    &:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgb(220, 53, 69);
    }
}
.select-search.is-invalid input {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    &:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgb(220, 53, 69);
    }
}
.form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.select-search.is-valid input {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.select-search.is-invalid .select-search__value::after,
.select-search.is-valid .select-search__value::after {
    right: 39px;
}
.md-form {
    .select-dropdown {
        label.active {
            color: #757575 !important;
            transform: none;
        }
    }
    margin: 0.5rem 0 !important;
}
.blackText {
    color: #000;
}
/* Network Managenment UI */
#networkManagement {
    a {
        color: var(--primary-color);
        &:hover {
            text-decoration: none;
        }
    }
    form label {
        margin-top: 0.6rem;
    }
    .quicklink {
        a {
            color: var(--primary-color) !important;
        }
    }
    .venue {
        display: none;
    }
    .residentSummary {
        background: (--primary-color);
    }
    .navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
        color: (--primary-color) !important;
    }
    .dropdown,
    .dropleft,
    .dropright,
    .dropup {
        .dropdown-menu {
            .dropdown-item {
                &:active {
                    background-color: (--primary-color);
                }
                &:hover {
                    background-color: (--primary-color);
                }
            }
        }
    }
    .managementModalButton:hover > p,
    .managementModalButton:hover,
    .managementModalButton:hover > .csv-reader-input label,
    .quicklink:hover,
    .quicklink:hover > a,
    .quicklink:hover p {
        background: (--primary-color);
        color: #fff;
    }
    .recentResidents .mdb-dataTable-head,
    .recentResidentsViewModify .mdb-dataTable-head,
    .modifyStaff .mdb-dataTable-head,
    .supportTickets .mdb-dataTable-head,
    .profilesData .mdb-dataTable-head,
    .messageData .mdb-dataTable-head,
    .subscribersTable .mdb-dataTable-head {
        background: (--primary-color);
        color: #fff;
        border: 1px solid (--primary-color);
    }
    .md-form input {
        border-bottom: (--primary-color) !important;
        box-shadow: 0 1px 0 0 (--primary-color) !important;
        margin: 0;
    }
    .managementModalButton {
        padding: 10px;
        border: 1px solid (--primary-color);
        border-radius: 5px;
        text-align: center;
        width: 65%;
        margin: 0 auto;
        color: (--primary-color);
        min-height: 120px;
        margin-bottom: 20px;
    }
    table.mdb-dataTable tbody > tr:not(.selected):hover {
        cursor: pointer;
    }
    .btn-danger {
        color: #fff;
        background-color: (--primary-color) !important;
    }
    .btn-primary {
        color: #fff;
        background-color: (--primary-color) !important;
        border-color: (--primary-color);
    }
    .nav-tabs {
        a {
            font-size: 14px !important;
            &.active {
                color: (--primary-color) !important;
            }
        }
        .nav-link {
            &.active,
            &:hover {
                color: (--primary-color) !important;
            }
        }
    }
    #selectVenue {
        .select-dropdown {
            font-weight: 700;
            color: #4285f4;
            font-size: 20px !important;
            box-shadow: none !important;
            margin: 0;
            padding-left: 8px;
            min-width: 150px;
            white-space: pre;
        }
    }
    #selectGroup,
    #selectKeypool,
    #selectPurpose,
    #selectServicePlan,
    #selectVenueSubscriberModal,
    #selectVenueResidentModal,
    #selectUnit,
    #selectBuilding,
    #selectFloor,
    #selectLanguage,
    #selectServicePlan {
        margin: 0 !important;
        ul {
            padding-top: 0.5rem;
        }
        label {
            top: -17px;
        }
        &.select-wrapper {
            span.caret {
                position: absolute;
                top: 0.8rem;
                right: 10px;
                font-size: 0.63rem;
                color: #fff;
                padding-left: 30px;
            }
        }
        &.md-form {
            input {
                &.selectInput {
                    border-bottom: none !important;
                    box-shadow: none !important;
                    line-height: inherit;
                    margin: 0;
                }
            }
        }
        &.form-control {
            &.is-invalid {
                background-position: right 20px center;
                border-color: #dc3545;
                padding-right: calc(1.5em + 0.75rem);
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
                &:focus {
                    border-color: #dc3545;
                    box-shadow: 0 0 0 0.2rem rgb(220, 53, 69);
                }
            }
        }
        .dropdown-content {
            left: 0;
            max-height: 200px;
            background: var(--input-color);
            li > a,
            li > span {
                color: #000;
            }
            li {
                &:hover {
                    background: var(--background-color);
                }
            }
        }
        .custom-select {
            min-height: 36.5px;
        }
    }
    #selectSearchInput.form-control.is-valid,
    .was-validated #selectSearchInput.form-control:valid,
    .was-validated #selectSearchInput.form-control:valid {
        background-image: none;
    }
    .select-wrapper ~ .invalid-feedback,
    .select-wrapper ~ .valid-feedback {
        margin-top: 3px;
    }
    input[type="text"].selectUnit,
    input[type="text"].selectKeypool,
    input[type="text"].selectPurpose,
    input[type="text"].selectLanguage,
    input[type="text"].selectServicePlan,
    input[type="text"].selectVenueSubscriberModal {
        height: 22px;
    }
    .card {
        min-height: 233px;
        margin-bottom: 15px;
    }
    #selectTimeZone input.select-dropdown {
        caret-color: transparent;
        top: 0;
        position: absolute;
        line-height: 30px;
        border-bottom: none !important;
        box-shadow: none !important;
        font-size: 14px !important;
        width: 90%;
    }
    #selectVenue {
        .select-dropdown {
            font-weight: 700;
            color: #4285f4;
            font-size: 20px !important;
            box-shadow: none !important;
            margin: 0;
            padding-left: 8px;
            min-width: 150px;
            white-space: pre;
        }
        .md-form {
            input {
                border-bottom: #4285f4 !important;
                box-shadow: 0 1px 0 0 #4285f4 !important;
            }
        }
        &.md-form .prefix.active,
        &.md-form .active {
            color: #000 !important;
        }
        .select-wrapper span.caret {
            position: absolute;
            top: 7px;
            right: 0;
            font-size: 0.63rem;
            color: #4285f4;
        }
        .dropdown-content {
            li > a,
            li > span {
                display: block;
                padding: 0.5rem;
                font-size: 0.9rem;
                color: #212529;
                font-weight: 400;
            }
        }
    }
    #selectVenueResidentModal {
        .md-form {
            input {
                border-bottom: #4285f4 !important;
                box-shadow: 0 1px 0 0 #4285f4 !important;
            }
        }
        .dropdown-content {
            li > a,
            li > span {
                display: block;
                padding: 0.5rem;
                font-size: 0.9rem;
                color: #212529;
                font-weight: 400;
            }
        }
    }
    input[type="text"].selectInput {
        height: 25px;
    }
    .servicePlans .justify-content-end,
    .upcomingExpirations .justify-content-end {
        display: none !important;
    }
    .servicePlans .mdb-dataTable-head {
        background: var(--primary-color);
        color: #fff;
        border: 1px solid var(--primary-color);
    }
    .servicePlans
        .servicePlans
        table.mdb-dataTable
        tbody
        > tr:not(.selected):hover,
    .servicePlans .keyPools table.mdb-dataTable tbody > tr:not(.selected):hover,
    .servicePlans
        .modifyAdminStaff
        table.mdb-dataTable
        tbody
        > tr:not(.selected):hover,
    .keypoolInfoTable
        .servicePlans
        table.mdb-dataTable
        tbody
        > tr:not(.selected):hover,
    .servicePlans table.mdb-dataTable tbody > tr:not(.selected):hover {
        border: none !important;
        cursor: initial;
    }
    .servicePlans a.expirationAction {
        padding: 5px;
        background: var(--primary-color);
        color: #fff !important;
        border-radius: 5px;
        margin-right: 5px;
        &.disabled {
            pointer-events: none;
            cursor: default;
        }
    }
    .recentResidents p {
        margin-bottom: 0;
    }
    .venueSelector #selectVenueResidentModal {
        margin: 0 !important;
    }
    #csvVenueUnitUploadData {
        width: 100%;
        left: 0;
    }
    .allVenues .recentResidents {
        max-height: fit-content;
    }
    .allVenues {
        max-height: 263px;
        // min-height: 273px;
        overflow: hidden;
    }
    .allVenuesExpanded {
        max-height: none;
        overflow: hidden;
    }
    .allVenuesExpanded .recentResidents {
        max-height: none;
    }
    .editorHeader {
        height: 100px;
    }
    .editorMessage {
        height: 300px;
    }
    .editorTandC {
        height: 400px;
    }
    .tag-item {
        background-color: #d4d5d6;
        display: inline-block;
        font-size: 14px;
        border-radius: 30px;
        height: 30px;
        padding: 0 4px 0 1rem;
        display: inline-flex;
        align-items: center;
        margin: 0 0.3rem 0.3rem 0;
    }
    .tag-item > .button {
        background-color: white;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        font: inherit;
        margin-left: 10px;
        font-weight: bold;
        padding: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    // .rdw-editor-toolbar,
    // .sendEmailEditor {
    //   padding: 6px 5px 0;
    //   border-radius: 2px;
    //   border: 1px solid #F1F1F1;
    //   display: flex;
    //   justify-content: flex-start;
    //   background: white;
    //   flex-wrap: wrap;
    //   font-size: 15px;
    //   margin-bottom: 5px;
    //   -webkit-user-select: none;
    //   -ms-user-select: none;
    //   user-select: none;
    // }
}
/* Subscriber UI */
#subscriberUI {
    background: var(--background-color);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .SelectPlan,
    .CreateAccount {
        position: relative;
        padding: 0px;
        min-height: 600px;
        .topBanner {
            background: linear-gradient(
                90deg,
                var(--banner-color-1),
                var(--banner-color-2)
            );
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 65px;
            width: 100%;
            left: 0;
            margin-top: 0px;
            h2 {
                color: var(--button-text-color);
                font-size: 18px;
            }
            a {
                color: #fff;
                text-decoration: underline;
            }
            .fa-times-circle {
                position: absolute;
                right: 10px;
                top: 140px;
                float: right;
                color: var(--button-text-color);
            }
            &.toggledOff {
                display: none;
            }
        }
        .Form {
            background: #fff;
            .loginDisclosure {
                .checkboxLabel {
                    padding-bottom: 0px !important;
                    left: 52px;
                }
            }
            .agree {
                padding-top: 0;
                #otherSpecify {
                    margin-left: 15px;
                    max-width: 440px;
                }
            }
        }
        .successModal {
            text-align: center;
        }
        input {
            margin-bottom: 0;
        }
    }
    .topBanner {
        background: linear-gradient(
            90deg,
            var(--banner-color-1),
            var(--banner-color-2)
        );
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        left: 0;
        // margin-top: 20px;
        h2 {
            color: var(--button-text-color);
            font-size: 18px;
            margin-bottom: 0px !important;
            padding: 10px;
        }
        a {
            color: #fff;
            text-decoration: underline;
        }
        .fa-times-circle {
            position: absolute;
            right: 10px;
            top: 140px;
            float: right;
            color: var(--button-text-color);
        }
        &.toggledOff {
            display: none;
        }
    }
    .ccInfo {
        img {
            border: 1px solid var(--border-color);
            border-radius: 5px;
        }
        .fab {
            color: #000;
            font-size: 20px;
        }
        a {
            margin-left: 0px;
        }
        .ccNumber {
            font-size: 18px;
        }
    }
    .upgradePlan {
        margin-left: 13px;
        margin-top: 10px;
        background-color: var(--primary-color) !important;
        &:hover {
            &:hover {
                background-color: var(--input-focus-color) !important;
                color: var(--button-text-color) !important;
            }
        }
    }
    .cancelService {
        top: -10px;
        position: relative;
        font-size: 12px;
        text-decoration: underline;
        a {
            color: var(--primary-text-color) !important;
        }
    }
    .modifyStaff {
        .mdb-dataTable-head {
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
    .supportTickets {
        .mdb-dataTable-head {
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
        }
    }
    .App {
        .container {
            text-align: left !important;
        }
        .returnButton {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                background: var(--primary-color) !important;
                color: var(--button-text-color) !important;
            }
        }
    }
}
.subscriberNavbar {
    border-bottom: 1px solid var(--border-color);
    background: var(--navbar-color);
    .mainNavigation {
        align-items: center;
    }
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-width='3' fill='currentColor' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
        color: #fff;
        display: inline-block;
    }
    .networkName {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        h1 {
            font-size: 24px;
            font-weight: bold;
            color: var(--navbar-text-color);
        }
    }
    .account {
        width: 240px;
        flex-direction: column;
        .select {
            position: absolute;
            top: 45px;
            right: 70px;
            display: none;
        }
        .colorMode {
            position: absolute;
            top: 10px;
            right: 25px;
        }
        .fa-concierge-bell {
            right: 100px;
            border-radius: 15px;
            width: 25px;
            height: 25px;
            top: 2px;
            margin-left: 10px;
            font-size: 14px;
            margin-right: 10px;
            margin-left: 5px !important;
            background: var(--primary-color);
            &:before {
                top: 5px;
                position: relative;
                left: -4.6px;
                color: #fff;
                left: 6px;
            }
        }
        .fa-user {
            right: 70px;
            border-radius: 15px;
            width: 25px;
            height: 25px;
            top: 2px;
            font-size: 14px;
            margin-right: 10px;
            margin-left: 5px !important;
            background: var(--primary-color);
            &:before {
                left: 6px;
                color: #fff;
            }
        }
        .accountLogout {
            padding-right: 10px;
            padding-left: 10px;
            a {
                padding: 0 0 0 10px !important;
            }
        }
        .dropdown-item {
            .nav-link {
                padding: 0 !important;
            }
        }
        .navbar {
            box-shadow: none;
            background-color: transparent !important;
            align-items: baseline;
            padding-top: 40px;
        }
        .switch label {
            color: var(--navbar-text-color);
            .lever {
                background-color: var(--primary-color-rgb) !important;
            }
            .lever:after {
                background-color: var(--primary-color) !important;
            }
        }
    }
    .nav-item {
        .topMenuItem {
            p {
                i.fa-sort-down {
                    transform: translateY(-24%);
                    display: block;
                    margin-left: 6px;
                }
                &:hover {
                    color: #fff !important;
                }
            }
        }
        a {
            &:hover {
                color: #fff !important;
                background: var(--primary-color);
                cursor: pointer;
            }
        }
    }
    li {
        display: block;
        a {
            color: #777 !important;
        }
    }
    .welcomeMessage {
        text-align: right;
        position: absolute;
        right: 30px;
        top: 30px;
        width: 200px;
        p {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .topMenuItem {
        &:hover {
            color: #fff !important;
            cursor: pointer;
            background: var(--primary-color);
        }
        p {
            color: var(--navbar-text-color);
        }
    }
    #navbarCollapse1 {
        background-color: var(--navbar-color) !important;
    }
    li a {
        color: var(--navbar-text-color) !important;
    }
}
.subscriberUIDashboard {
    background-color: var(--background-color) !important;
    .card {
        background-color: var(--card-color) !important;
        h3,
        span,
        p,
        .fa,
        .dropdown-toggle:after {
            color: var(--navbar-text-color) !important;
        }
        text {
            fill: var(--navbar-text-color) !important;
        }
        a.moreDetails {
            color: var(--navbar-text-color) !important;
        }
    }
    .modal-content {
        background-color: var(--modal-background-color) !important;
        h4,
        p,
        span,
        strong,
        label,
        text,
        .fa,
        .dropdown-toggle {
            color: var(--navbar-text-color) !important;
        }
        .password_subscriber {
            background-color: var(--background-color) !important;
        }
        text {
            fill: var(--navbar-text-color) !important;
        }
        tbody {
            background-color: var(--input-color) !important;
        }
    }
    .form-control {
        background-color: var(--input-color);
    }
    .dropdown-menu {
        background-color: var(--navbar-color) !important;
        button {
            color: var(--navbar-text-color) !important;
        }
    }
}
.account {
    .fa-user {
        &:before {
            top: 5px;
            position: relative;
            left: -4px;
            color: #fff;
        }
    }
}
.navbar {
    .dropdown-menu {
        a {
            &:hover {
                color: #fff !important;
            }
        }
    }
    align-items: center;
}
#navbarCollapse1 {
    background-color: #fff !important;
    padding: 10px;
    z-index: 1;
    box-shadow: 0 2px 5px 0 rgb(0, 0, 0), 0 2px 10px 0 rgb(0, 0, 0);
    margin-top: 10px;
}
.nav-item {
    .upgradePlan {
        padding: 0.84rem 2.14rem !important;
    }
    a {
        padding-left: 5px !important;
    }
}
.topMenuItem {
    p {
        align-items: center;
        display: flex;
        padding: 0.5rem 1rem !important;
        padding-left: 0px !important;
    }
}
.notifications {
    padding: 10px;
    background: #000;
    color: #fff;
    width: 97%;
    border-left: 1px solid var(--primary-color);
    p {
        margin: 0;
    }
    i.fa-times-circle {
        position: absolute;
        right: 20px;
        top: 7px;
        font-size: 25px;
        color: #000;
    }
}
.notifications.toggledOff {
    display: none;
}
.ctaButton {
    max-width: 280px;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    &:hover {
        background: #eee;
        cursor: pointer;
        color: var(--primary-color);
    }
    h2 {
        font-size: 18px;
    }
}
.ctaButtonNoAction {
    max-width: 280px;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    h2 {
        font-size: 18px;
    }
}
.keyInformation,
.editProfile {
    a.moreDetails {
        color: var(--primary-color) !important;
    }
    h3 {
        font-size: 16px;
        line-height: 21px;
    }
    .dropdown {
        a {
            color: #000;
            padding: 0px;
            font-size: 16px;
            font-weight: normal;
        }
        span {
            color: #000;
            padding: 0px;
            font-size: 100%;
            font-weight: normal;
        }
    }
    .select-search__input,
    .selectState select {
        height: 32px;
        border: none;
        padding-left: 5px;
    }
    .select-search__option {
        padding-left: 5px;
    }
    .fa-wifi {
        padding-right: 5px;
    }
    .card {
        min-height: 230px;
        p {
            padding: 0rem 1rem;
        }
    }
    #password {
        padding-right: 10px;
        // padding-left: 5px;
        font-size: 16px;
        font-family: "Source Code Pro", sans-serif;
    }
    .fa-copy {
        margin-left: 5px;
        &:hover {
            cursor: pointer;
        }
    }
    .fa-envelope {
        margin-left: 5px;
    }
    .fa-edit {
        margin-left: 10px;
    }
    .keyInfo {
        padding: 8px 0px;
    }
    .keyInfoLinks {
        padding: 6px 0px;
    }

    .guestData {
        max-height: 205px;
        .mdb-dataTable-head {
            color: #fff;
            background: var(--primary-color);
        }
        .table-responsive {
            max-height: 170px;
            overflow-x: hidden;
        }
        .mdb-datatable-info {
            display: none !important;
        }
        .mdb-dataTables_paginate {
            display: none !important;
        }
    }
    .deviceCardBody {
        .custom-right-arrow {
            margin-right: -5px;
        }
    }
    .qrCode,
    #qrCodeDiv {
        text-align: center;
        img {
            max-width: 100px;
        }
    }
}
.modal-body {
    .dropdown {
        span {
            color: #000;
            padding: 0px;
            font-size: 100%;
            font-weight: normal;
        }
    }
    .react-multi-carousel-list {
        position: initial;
        margin-left: 15px;
    }
}
.mdb-datatable {
    table {
        tr {
            border-bottom: none;
        }
    }
}
table.mdb-dataTable {
    tbody {
        > tr {
            &:not(.selected) {
                &:hover {
                    cursor: pointer;
                    background-color: var(--background-color) !important;
                    transition: 0s !important;
                }
            }
        }
    }
    thead {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled {
            &:before,
            &:after {
                left: 0px !important;
                font-size: 10px !important;
            }
        }
    }
    &.mdb-dataTable-head {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled {
            &:before,
            &:after {
                left: 0px !important;
                font-size: 10px !important;
            }
        }
    }
}
.react-multi-carousel-item {
    width: 120px !important;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 5px 5px 1px #888888;
}
.devices {
    display: flex;
}
.deviceCardBody {
    min-height: 200px;
    display: flex;
}
.messageCardBody {
    min-height: 200px;
    display: flex;
}
.deviceCard {
    &:hover {
        border-radius: 5px;
        border: 1px solid var(--primary-color);
    }
    text-align: center;
    min-height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 105px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    height: 80px;
    i {
        font-size: 30px;
    }
    .deviceStatusConnected {
        position: relative;
        width: 10px;
        height: 10px;
        background: green;
        border-radius: 10px;
        bottom: 30px;
        left: 25px;
    }
    .deviceStatusDisconnected {
        position: relative;
        width: 10px;
        height: 10px;
        background: #cc0000;
        border-radius: 10px;
        bottom: 30px;
        left: 25px;
    }
}
.deviceCardDashboard {
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p {
        word-break: break-word;
    }
    i {
        font-size: 30px;
    }
    .deviceStatusConnected {
        position: relative;
        width: 10px;
        height: 10px;
        background: green;
        border-radius: 10px;
        bottom: 30px;
        left: 25px;
    }
    .deviceStatusDisconnected {
        position: relative;
        width: 10px;
        height: 10px;
        background: #cc0000;
        border-radius: 10px;
        bottom: 30px;
        left: 25px;
    }
}
.custom-left-arrow {
    position: absolute !important;
    left: 10px;
    z-index: 1;
    border: 1px solid grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.8;
    cursor: pointer;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.active-circle {
    width: 10px;
    height: 10px;
    background: green;
    border-radius: 10px;
}
.inactive-circle {
    width: 10px;
    height: 10px;
    background: #cc0000;
    border-radius: 10px;
}
.modal-title {
    .addProfile {
        float: left;
        padding-left: 20px;
        margin-bottom: 0.5rem;
        line-height: initial;
        .fa-plus-circle {
            color: var(--primary-color);
        }
    }
}
.dropdown-toggle {
    padding: 0px;
    color: #000;
}
.formControls {
    display: flex;
    align-items: center;
    .fa {
        font-size: 18px;
    }
    .fa-download {
        margin-left: 10px;
    }
    .fa-copy {
        margin-left: 10px;
    }
    .fa-envelope {
        margin-left: 10px;
    }
}
.react-multi-carousel-list {
    position: initial;
}
.Toastify__toast--error {
    border-radius: 10px;
    line-break: normal;
    word-wrap: break-word;
    width: 100%;
}
.Toastify__toast-body {
    line-break: normal;
    word-wrap: break-word;
    width: 100%;
}
.editProfile {
    max-width: 1000px;
}
.inputBox {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    min-height: 31px;
}
.residentEmail {
    display: flex;
}
.passwordLabel {
    display: block;
}
.profilesData {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    .justify-content-end {
        display: none !important;
    }
    .mdb-dataTable-head {
        color: #fff;
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
    }
    table {
        thead {
            width: 100%;
            table-layout: fixed;
        }
        tbody {
            tr {
                width: 100%;
                table-layout: fixed;
            }
        }
    }
}
.messageData {
    .justify-content-end {
        display: none !important;
    }
    .mdb-dataTable-head {
        color: #fff;
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
    }
    table {
        thead {
            display: revert !important;
        }
        tbody {
            tr {
                display: revert !important;
            }
        }
    }
    max-height: 205px;
    overflow-y: auto;
    overflow-x: hidden;
}
input#editpassword {
    max-width: 220px;
    display: inline-block;
    margin-right: 5px;
    font-family: "Source Code Pro", sans-serif;
}
input#editpassword2,
#password {
    font-family: "Source Code Pro", sans-serif;
    display: inline-block;
}
.password_subscriber {
    font-size: 1rem;
    background: #ccc;
    padding: 5px;
    font-family: "Source Code Pro", sans-serif;
}
.weak {
    width: 220px !important;
    span {
        background-color: red !important;
    }
}
.average {
    width: 220px !important;
    span {
        background-color: yellow !important;
    }
}
.strong {
    width: 220px !important;
    span {
        background-color: green !important;
    }
}

label#invalidPassword {
    color: red !important;
}

.invalidCharacter {
    margin-bottom: 2px !important;
    &:before {
        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 12 12'%3e%3cpath fill='red' d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708'/%3e%3c/svg%3e") !important;
        padding-right: 5px;
    }
}
.validCharacter {
    &:before {
        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 12 12'%3e%3cpath fill='%2328a745' d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0'/%3e%3c/svg%3e") !important;
        padding-right: 5px;
    }
}
.custom-right-arrow {
    position: absolute !important;
    right: 10px;
    z-index: 1;
    border: 1px solid grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.8;
    cursor: pointer;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.deviceDetails {
    .fa {
        font-size: 30px;
    }
    .fa-edit {
        font-size: 15px;
    }
    .deviceStatusConnected {
        position: relative;
        width: 10px;
        height: 10px;
        background: green;
        border-radius: 10px;
        margin-right: 10px;
        right: -4px;
    }
    .deviceStatusDisconnected {
        position: relative;
        width: 10px;
        height: 10px;
        background: #cc0000;
        border-radius: 10px;
        margin-right: 10px;
        right: -4px;
    }
}
#passwordHidden,
#venueIDHidden,
#externalVenueIDHidden,
#subscriberTokenHidden,
#externalSubscriberIDHidden,
#stripeCustomerIDHidden {
    display: none;
}
.messageSubject {
    padding-bottom: 20px;
}
.unreadMessage {
    p {
        font-weight: bold;
        padding: 0 !important;
    }
}
.readMessage {
    p {
        padding: 0 !important;
    }
}
.messageDataModal {
    .messageData {
        max-height: none;
    }
}
/* Create Account */
.SelectPlan {
    bottom: initial !important;
}
.PaymentSuccess {
    li {
        padding-bottom: 20px;
    }
    .gigstreem {
        li {
            padding-bottom: 20px;
            font-size: 22px;
        }
    }
    .promoImg {
        img {
            width: 100% !important;
        }
    }
    .disclaimer {
        font-size: 12px;
    }
}
.CreateAccount,
.FindDeals,
.SelectPlan,
.PaymentSuccess,
.SelectPlanFacts {
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    background-color: #fff;
    padding: 40px 0px 80px;
    form {
        margin: 0 auto;
        max-width: 500px;
    }
    a {
        text-decoration: none;
        &.underline {
            text-decoration: underline;
            color: var(--primary-color) !important;
        }
        .fa {
            padding-left: 10px;
        }
    }
    p {
        &.small {
            font-size: 15px;
        }
        font-size: 20px;

        margin-bottom: 0px;
        &.title {
            font-size: 22px;
            color: var(--primary-color) !important;
            margin: 20px 0px;
        }
    }
    .Logo {
        text-align: center;
        img {
            padding-bottom: 40px;
            width: 240px;
        }
    }
    .modal-header {
        border-bottom: none !important;
        .modal-title {
            color: var(--primary-color) !important;
            margin: 0 auto;
        }
    }
    .modal-body {
        form {
            max-width: 100%;
        }
    }
    .Form {
        background: #fff;
        padding: 0;
        .FormContainer {
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 40px;
            text-align: center;
            margin: 0 auto;
            max-width: 500px;
            &.noMaxWidth {
                max-width: 600px;
            }
            h2 {
                padding-bottom: 10px;
            }
            padding-bottom: 30px;
            .congratulations {
                padding-bottom: 40px;
            }
            ol {
                text-align: left;
                padding-bottom: 40px;
            }
            .invalid {
                text-align: left;
                margin-top: -10px;
                margin-bottom: 10px;
            }
            #email {
                margin-top: 10px !important;
            }
        }
        h2 {
            font-size: 18px;
            color: var(--primary-text-color);
        }
        p {
            color: var(--primary-text-color);
        }
        a {
            color: var(--primary-text-color);
        }
        .loginDisclosure {
            padding-top: 20px;
            .checkboxLabel {
                left: 44px;
                padding-bottom: 20px;
                top: 3px;
                position: absolute;
                a,
                button {
                    text-decoration: underline;
                    color: var(--primary-color);
                }
            }
            p {
                font-size: 1rem;
                text-align: left;
            }
            .plan {
                padding-bottom: 20px;
            }
            .planImage {
                max-width: 200px;
            }
        }
        .agree {
            padding-top: 20px;
        }
    }
    #selectUnit {
        padding: 0px;
    }
    .select-search {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .btn-default {
        border-radius: 0.125rem;
        height: 39px;
        padding: 0px;
        &.selectPlanBtn {
            margin: 5px 0px !important;
        }
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        &.centered {
            margin: 0 auto !important;
        }
        &.btn {
            &.disabled {
                opacity: 0.7;
                background-color: var(--primary-color) !important;
            }
        }
    }
    .fa-user,
    .fa-check {
        right: 70px;
        border-radius: 15px;
        width: 25px;
        height: 25px;
        top: 2px;
        font-size: 14px;
        margin-right: 10px;
        background: var(--primary-color);
        &:before {
            top: 5px;
            position: relative;
            left: 0px;
            color: #fff;
        }
    }
    input,
    .PhoneInput {
        margin-bottom: 20px;
        &#phoneNumber {
            margin-bottom: 0px;
        }
    }
    .dateTimePicker {
        margin-bottom: 20px;
    }
    .invalidDaysSelected {
        margin-bottom: 20px;
    }
    .topBanner {
        background: linear-gradient(
            90deg,
            var(--banner-color-1),
            var(--banner-color-2)
        );
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        width: 100%;
        left: 0;
        h2 {
            text-align: center;
            color: var(--button-text-color);
        }
        a {
            color: #fff;
        }
    }
    .checkOutBanner {
        background: linear-gradient(
            90deg,
            var(--banner-color-1),
            var(--banner-color-2)
        );
        margin-top: 20px;
        padding: 10px 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: auto;
        width: 100%;
        left: 0;
        .checkOutBannerText {
            max-width: 475px;
            padding: 20px;
        }
        h2 {
            text-align: left;
            color: var(--button-text-color);
        }
        a {
            color: #fff !important;
            text-decoration: underline !important;
            float: right;
        }
    }
    select {
        font-family: "Poppins", sans-serif !important;
        font-size: 16px !important;
        background-image: none;
    }
    select option {
        color: #000 !important;
    }
    .select-search__input {
        color: #495057;
    }
    a.btn-default,
    button.btn-default {
        background-color: var(--primary-color) !important;
        color: #fff !important;
        &:hover {
            background-color: var(--input-focus-color) !important;
            color: var(--primary-color) !important;
        }
    }
    .btn-default:not([disabled]):not(.disabled):active,
    .btn-default:not([disabled]):not(.disabled).active,
    .show > .btn-default.dropdown-toggle {
        background-color: var(--primary-color) !important;
    }
    .SelectPlan {
        .plan {
            padding-bottom: 20px;
            img {
                width: 120px;
            }
        }
        .btn-default {
            border-radius: 20px;
            height: 30px;
            padding: 0px 30px;
            margin: 10px 0px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
        }
    }
    .leftAlign {
        text-align: left;
        padding-left: 0px;
        padding-bottom: 20px;
    }
    .backButton {
        float: left;
        a {
            z-index: 1;
        }
    }
    .nextButton {
        float: right;
        a {
            z-index: 1;
        }
    }
    .faqModal {
        p {
            font-size: 16px;
        }
    }
    input {
        &#smsCode {
            text-align: center;
        }
    }
    .servicePlanFactsNames {
        list-style-type: none;
        overflow-x: auto;
        padding: 0.25rem;
        white-space: nowrap;
        display: flex;
        li {
            float: left;
            button {
                display: block;
                text-align: center;
                padding: 5px 10px;
                text-decoration: none;
                background: var(--button-text-color);
                border-radius: 20px;
                margin: 10px;
                color: var(--primary-color);
                border: 1px solid var(--primary-color);
                &:hover {
                    background: var(--primary-color) !important;
                    color: var(--button-text-color) !important;
                }
            }
            .btn-active {
                background: var(--primary-color);
                color: var(--button-text-color);
            }
        }
    }
    .servicePlanFactsSheet {
        text-align: left;
        p {
            &.regularText {
                font-size: 14px;
                font-weight: normal;
            }
        }
        hr {
            border: 2px solid var(--border-color) !important;
        }
        a {
            text-decoration: underline;
            color: var(--primary-color);
        }
    }
}
.footerCreateAccount {
    background: var(--primary-color);
    color: #fff;
    height: auto;
    width: 100%;
    padding: 10px 0px;
    text-align: center;
    bottom: 0;
    position: relative;
    margin-top: -54px;
    flex-shrink: 0;
    p {
        font-size: 12px;
        color: #fff;
        margin-bottom: 0px;
    }
    .piped span {
        border-left: 1px solid #ccc;
        padding: 0 10px;
    }
    .piped span:first-child {
        border-left: none;
        padding-left: 0;
    }
    .text-piped span:after {
        content: "|";
        margin: 0 10px;
    }
    .text-piped span:last-child:after {
        content: "";
        margin: 0;
    }
    a,
    button {
        color: #fff;
    }
}
.navbar {
    height: 130px;
}
.footer {
    padding-bottom: 20px;
    background-color: var(--background-color);
    &.subscriberUI {
        padding-top: 20px;
    }
    .securedByVaultImage {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        img {
            width: 150px;
        }
    }
}
#venueManagement.dishdashboard {
    .mainNavigation {
        a.logout {
            &:hover {
                background-color: #eee !important;
                color: #000 !important;
            }
        }
    }
}
.venue {
    position: absolute;
    left: 150px;
    top: 0;
    z-index: 1;
    .nav-link {
        padding: 1rem 1rem 0 1rem;
    }
}
.account {
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px;
    .accountLogout {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            padding: 0 10px !important;
            font-weight: normal;
            font-size: 12px;
        }
        .fa {
            padding-left: 10px;
            color: #000;
            font-size: 20px;
        }
        .accountSwitch {
            padding: 0px !important;
        }
    }
}
.dropdown-toggle {
    span {
        font-weight: bold;
        font-size: 20px;
    }
}
.select-dropdown {
    label {
        top: -10px;
        transition: initial;
        transform: none;
        transform-origin: 0%;
    }
}
input[type="text"].selectInput {
    height: 25px;
}
#selectVenue.md-form {
    .active {
        color: #000 !important;
    }
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
}
.MuiAutocomplete-hasPopupIcon {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0px;
        padding-right: 0px !important;
    }
}
.MuiAutocomplete-hasClearIcon {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0px;
        padding-right: 0px !important;
    }
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    .MuiAutocomplete-input {
        &:first-child {
            font-weight: 700;
            font-size: 20px !important;
            box-shadow: none !important;
            margin: 0;
            padding: 0px 0px 0px 8px !important;
            min-width: 150px;
            white-space: pre;
            font-family: revert;
        }
    }
    .MuiAutocomplete-endAdornment {
        right: -9px !important;
    }
}
.MuiOutlinedInput-notchedOutline {
    border: none !important;
}
.MuiFormControl-fullWidth {
    box-sizing: border-box;
    width: 100% !important;
}
.MuiInputBase-fullWidth {
    box-sizing: border-box;
    width: 100% !important;
}
.venueNameModal {
    margin: 0 auto;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        .MuiAutocomplete-input {
            &:first-child {
                font-weight: 700;
                font-size: 20px !important;
                box-shadow: none !important;
                margin: 0;
                padding: 0px 0px 0px 0px !important;
                min-width: 150px;
                white-space: pre;
            }
        }
    }
}
#mainPopper {
    .MuiAutocomplete-popper {
        max-width: 300px;
        min-width: 300px;
        right: 0;
        transform: translate3d(155px, 40px, 0px) !important;
        will-change: unset !important;
    }
}
.address {
    p {
        margin-bottom: 0;
    }
}
.mainDashboard {
    padding-top: 20px;
}
.dashboardRow {
 .dashboardCard{
  padding-top: 20px;
 }
}
.search {
    .input {
        width: 100%;
    }
}
.ui.search {
    .prompt {
        border-radius: 5px;
    }
}
.category {
    .name {
        display: none !important;
    }
}
.results {
    .description {
        white-space: pre-line;
    }
}
.summaryCard {
    h2 {
        font-size: 18px !important;
        font-weight: bold !important;
        margin-bottom: 0.5rem !important;
    }
    p {
        color: #fff;
        font-size: 16px;
        margin: 5px;
    }
    a {
        color: #000;
    }
    .row {
        padding: 8px 0px;
    }
}
.modal-body {
    hr {
        border: 1px solid var(--border-color) !important;
    }
    .scrollableList {
        max-height: 160px;
        overflow: auto;
        margin-bottom: 10px;
    }
}
.rounded {
    border-radius: 10px;
}
.support {
    background: var(--additional-color);
    padding: 10px;
    min-height: 170px;
}
.warning-light {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    background: yellow;
    margin: 5px 0px;
    position: absolute;
}
.error-light {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    background: red;
    margin: 5px 0px;
    position: absolute;
}
.success-light {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    background: green;
    margin: 5px 0px;
    position: absolute;
}
.tooltipSummary {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    background: #ccc;
    margin: 5px 0px;
    position: absolute;
    left: 45px;
    p {
        margin: 0 6px;
        color: #000;
    }
}
.tooltip {
    width: 100px !important;
}
.grid {
    margin-bottom: 10px;
    p {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 0px;
    }
}
.quicklinks {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 17px;
    .fa {
        font-size: 40px;
    }
    .fas {
        font-size: 40px;
    }
    p {
        font-size: 14px;
        margin-top: 10px;
        white-space: pre-line;
        color: #000;
    }
    .col-xs-6 {
        width: 50%;
    }
}
.quicklink {
    width: 125px;
    margin: 0 auto;
    border-radius: 5px;
    &:hover {
        border-radius: 5px;
        > a {
            border-radius: 5px;
        }
        p {
            border-radius: 5px;
        }
    }
}
.residentSummary {
    padding: 10px;
}
.table {
    td {
        padding: 0.75rem !important;
        white-space: pre;
    }
    th {
        padding: 0.75rem !important;
    }
}
.recentResidents {
    max-height: 165px;
    overflow: hidden;
    .justify-content-end {
        display: none !important;
    }
    .mdb-dataTable-head {
        color: #fff;
    }
}
.noPointer {
    table.mdb-dataTable {
        tbody {
            > tr {
                > td {
                    cursor: default;
                }
            }
        }
    }
    cursor: initial !important;
}
.upcomingExpirations,
.upcomingExpirationsExpanded {
    max-height: 165px;
    overflow: hidden;
    .justify-content-end {
        display: none !important;
    }
    .mdb-dataTable-head,
    .MuiDataGrid-columnHeaders,
    .MuiDataGrid-container--top [role="row"] {
        background: #000 !important;
        color: #fff;
    }
    table.mdb-dataTable {
        tbody {
            > tr {
                &:not(.selected) {
                    &:hover {
                        border: none !important;
                        cursor: initial;
                    }
                }
            }
        }
    }
    a.expirationAction {
        padding: 5px;
        color: #fff !important;
        border-radius: 5px;
        margin-right: 5px;
    }
}
.recentResidentsViewModify {
    max-height: 190px;
    overflow: hidden;
    .justify-content-end {
        display: none !important;
    }
    .mdb-dataTable-head {
        color: #fff;
    }
}
.modifyStaff {
    .justify-content-end {
        display: none !important;
    }
    .mdb-dataTable-head {
        color: #fff;
    }
}
.supportTickets {
    .mdb-dataTable-head {
        color: #fff;
    }
}
.modifyStaff .mdb-dataTable {
    td {
        line-break: auto;
    }
}
.expandCollapse {
    position: absolute;
    right: 20px;
    top: 20px;
}
.expandCollapseExpirations {
    position: absolute;
    right: 20px;
    top: 20px;
}
.expandCollapseModify {
    right: 0;
    padding: 10px;
    z-index: 1;
    text-align: right;
}
.recentResidents {
    display: block;
}
.allrecentresidents {
    display: none;
}
.recentresidentsExpanded {
    display: none;
}
.allrecentresidentsExpanded {
    .recentResidents {
        max-height: fit-content;
        display: block;
    }
}
.upcomingExpirationsExpanded {
    .upcomingExpirations {
        max-height: fit-content;
    }
}
.recentresidentsViewModifyExpanded {
    .recentResidentsViewModify {
        max-height: fit-content;
    }
}
.card-body {
    h2 {
        font-size: 18px;
    }
}
.actionSummary {
    background: #aaa;
    color: #fff;
    padding: 10px;
}
.modal {
    overflow-y: auto !important;
    .searchForm {
        margin-top: 20px;
        border: 1px solid #dee2e6;
        padding: 10px;
        border-radius: 5px;
    }
}
.modal-content {
    border-radius: 10px !important;
    .form-check-inline {
        .form-check {
            padding: 0 10px 10px;
            input {
                height: 0;
            }
        }
    }
    .form-check {
        padding-left: 0 !important;
        padding-top: 10px;
    }
    p.wifiDisclaimer {
        font-weight: bold;
        font-size: 16px;
    }
}
.modal-title {
    h4 {
        font-size: 20px;
        text-align: center;
    }
    margin: 0 auto;
    font-size: 20px;
    .dropdown {
        text-align: center;
    }
}
.form {
    .modal-title {
        h4 {
            text-align: left;
            float: left;
        }
        p {
            font-size: 12px;
            color: #888;
            float: left;
            padding: 0px 40px;
        }
        margin: 0;
    }
}
.modal-header {
    .close {
        position: absolute;
        right: 16px;
    }
}
.managementModal {
    .row {
        padding-bottom: 20px;
    }
}
.managementModalButton {
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    min-height: 120px;
    .fa {
        font-size: 40px;
        padding-bottom: 10px;
    }
    p {
        font-size: 16px;
        margin-bottom: 0px;
        color: var(--primary-text-color);
        white-space: pre-line;
    }
    &:hover {
        > p {
            color: #fff;
            border-radius: 5px;
        }
        color: #fff;
        border-radius: 5px;
        > .csv-reader-input {
            label {
                color: #fff;
                border-radius: 5px;
            }
        }
    }
}
.newTicketModal {
    min-height: 600px;
}
.newTicketModalDescription {
    min-height: 300px;
}
.newTicketModalButtonSelected {
    color: #fff !important;
    font-size: 16px;
    margin-bottom: 0px;
    color: #000;
    white-space: pre-line;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 200px;
    margin: 0 auto;
    min-height: 80px;
    .fa {
        font-size: 40px;
        padding-bottom: 10px;
    }
}
.newTicketModalButton {
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 200px;
    margin: 0 auto;
    min-height: 80px;
    p {
        font-size: 16px;
        margin-bottom: 0px;
        color: #000;
        white-space: pre-line;
    }
    .fa {
        font-size: 40px;
        padding-bottom: 10px;
    }
    &:hover {
        > p {
            color: #fff;
        }
        color: #fff;
        > .csv-reader-input {
            label {
                color: #fff;
            }
        }
    }
}
.newTicketModalButtonSelectedSM {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;
    color: #000;
    white-space: pre-line;
    padding: 1px;
    border-radius: 5px;
    text-align: center;
    width: 50px;
    margin: 0 auto;
    min-height: 20px;
    .fa {
        font-size: 20px;
        padding-bottom: 1px;
    }
    p {
        font-size: 12px;
        margin-bottom: 0px;
        color: #000;
        white-space: pre-line;
    }
}
.newTicketModalButtonSM {
    padding: 1px;
    border-radius: 5px;
    text-align: center;
    width: 50px;
    margin: 0 auto;
    min-height: 20px;
    p {
        font-size: 12px;
        margin-bottom: 0px;
        color: #000;
        white-space: pre-line;
    }
    .fa {
        font-size: 20px;
        padding-bottom: 1px;
    }
    &:hover {
        > p {
            color: #fff;
        }
        color: #fff;
        > .csv-reader-input {
            label {
                color: #fff;
            }
        }
    }
}
.stepperSelected {
    color: #fff;
}
.stepperNotSelected {
    color: black;
}
.mdb-react-date__picker {
    label {
        display: none;
    }
}
.textField {
    display: block !important;
    input.MuiInputBase-input {
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem;
    }
}
.dateTimePicker {
    width: 100%;
    padding: 0px 0px 0px 0px !important;
    border: var(--border-color) 1px solid !important;
    border-radius: 4px;
    .input {
        width: 100%;
    }
    input {
        border: none !important;
        padding: 7px !important;
        width: 100%;
    }
}
.MuiInput-underline {
    &:before {
        border-bottom: none !important;
    }
    &:after {
        border-bottom: none !important;
    }
}
.submitButton {
    position: relative;
    padding-top: 60px;
}
.csv-input {
    color: transparent;
    position: absolute;
    height: 100%;
    z-index: 1;
    display: block;
    top: 0;
    left: 63px;
    width: 53%;
    opacity: 0;
    &::before {
        display: none;
    }
}
.csv-label {
    color: #000;
    margin: 0px;
    font-size: 16px;
}
a.map {
    width: 98%;
    height: 250px;
    left: 9px;
    position: relative;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
div[id^="deactivateApprove_scratchcards"] {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
div[id^="overlay_scratchcards"] {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
.overlay2 {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#deactivateApprove {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#overlayConfirm {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#overlayAdd {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#overlayResidentManagement {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#overlaySettings {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#overlayViewModifyResident {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#overlayResidentManagementRecent {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
#overlayError {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
}
.overlayMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    background: #fff;
    color: #000;
    padding: 20px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    border-radius: 5px;
    text-align: center;
    .btn-default {
        background: var(--primary-color) !important;
    }
}
.overlayProgressBar {
    position: relative;
    font-size: 16px;
    background: #fff;
    color: #000;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}
.DayPickerInput {
    display: block;
    input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}
.invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.modal-fluid.modal-dialog.form {
    width: 85%;
}
.PhoneInput.invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.loading {
    background-color: #ffffff;
    background-image: url("https://thomas.vanhoutte.be/miniblog/wp-content/uploads/spinner-rosetta-gray-26x26.gif");
    background-size: 25px 25px;
    background-position: right center;
    background-repeat: no-repeat;
}
.renewConfirmButton1 {
    display: inline-block;
}
.renewConfirmButton2 {
    display: inline-block;
}
.keypoolButtons {
    float: right;
    .showPasswordButton {
        display: inline-block;
        text-align: right;
    }
}

.envelopeIcon {
    i {
        padding-right: 10px;
        padding-bottom: 10px;
    }
    span {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
.fa-copy {
    i {
        padding-right: 10px;
        padding-bottom: 10px;
    }
    span {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
mark {
    background-color: #ff0;
    color: #000;
}
#selectUnit.select-wrapper {
    span.caret {
        position: absolute;
        top: 0.8rem;
        right: 10px;
        font-size: 0.63rem;
        color: #495057;
        padding-left: 30px;
    }
}
#selectBuilding.select-wrapper {
    span.caret {
        position: absolute;
        top: 0.8rem;
        right: 10px;
        font-size: 0.63rem;
        color: #495057;
        padding-left: 30px;
    }
}
#selectFloor.select-wrapper {
    span.caret {
        position: absolute;
        top: 0.8rem;
        right: 10px;
        font-size: 0.63rem;
        color: #495057;
        padding-left: 30px;
    }
}
#selectLanguage.select-wrapper {
    span.caret {
        position: absolute;
        top: 0.8rem;
        right: 10px;
        font-size: 0.63rem;
        color: #495057;
        padding-left: 30px;
    }
}
#selectServicePlan.select-wrapper {
    span.caret {
        position: absolute;
        top: 0.8rem;
        right: 10px;
        font-size: 0.63rem;
        color: #495057;
        padding-left: 30px;
    }
}
#selectSearchInput.form-control.is-valid {
    background-image: none;
}
.was-validated {
    #selectSearchInput.form-control {
        &:valid {
            background-image: none;
        }
    }
}
.select-wrapper {
    ~ {
        .invalid-feedback {
            margin-top: 3px;
        }
        .valid-feedback {
            margin-top: 3px;
        }
    }
}
input[type="text"].selectUnit {
    height: 22px;
}
input[type="text"].selectBuilding {
    height: 22px;
}
input[type="text"].selectFloor {
    height: 22px;
}
input[type="text"].selectLanguage {
    height: 22px;
}
input[type="text"].selectServicePlan {
    height: 22px;
}
@media all and (max-width: 991px) {
    .Login {
        min-height: 600px;
        padding-top: 80px !important;
        background-position: top !important;
        padding: 60px 0px;
        .Logo {
            display: none;
        }
        button {
            width: 100%;
        }
        .bottomBanner {
            display: none;
        }
        .logoMobile {
            display: block;
        }
    }
    .logoMobile {
        text-align: center;
        display: block;
        img {
            padding-bottom: 20px;
            max-width: 280px;
        }
    }
    .redBanner {
        background: baseColor;
        margin-bottom: 30px;
    }
    .loginForm {
        h2 {
            display: none;
        }
        background: #000010;
    }
    .bottom-banner {
        display: none;
    }
    .CreateAccount,
    .FindDeals {
        min-height: 1050px;
        padding-top: 80px !important;
        background-position: top !important;
        padding: 20px 0px !important;
        button {
            width: 100%;
        }
    }
}
@media all and (max-width: 576px) {
    .Login {
        .form-check {
            float: left;
            padding-left: 0rem;
        }
        form {
            margin: none !important;
        }
    }
    .AUPTitle {
        .Logo {
            display: none;
        }
    }
}
@media all and (max-width: 355px) {
    .Login,
    .CreateAccount {
        min-height: 700px;
    }
}
@media screen and (max-width: 1311px) {
    .renewConfirmButton1 {
        display: inline-flex;
        width: 100%;
        text-align: center;
    }
    .renewConfirmButton2 {
        display: inline-flex;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .upcomingExpirations {
        a.expirationAction {
            width: 100%;
        }
    }
}
@media screen and (max-width: 1024px) {
    .modal-fluid.modal-dialog.form {
        width: 100%;
    }
}
@media screen and (max-width: 992px) {
    .mainNavigation {
        display: flex;
        align-items: flex-end;
        position: absolute;
        min-height: 94px;
        right: 0;
        bottom: 0;
        margin-bottom: 0 !important;
        a.nav-link {
            font-size: 16px;
        }
    }
    .account {
        right: 0px;
        a.nav-link {
            font-size: 12px;
        }
    }
    .quicklinks {
        position: relative;
        padding-top: 20px;
    }
    .padding {
        padding-top: 20px;
    }
    .managementModalButton {
        width: 100%;
        p {
            font-size: 14px;
        }
    }
}
@media screen and (max-width: 767px) {
    #venueManagement,
    #networkManagement {
        .mainNavigation {
            position: inherit;
            min-height: 36px;
            a.nav-link {
                padding: 13px 13px 5px 13px !important;
                font-size: 14px;
            }
            a.nav-link.firstNav {
                padding-left: 0px !important;
            }
            .address {
                padding-left: 0px !important;
            }
            .select {
                position: relative;
                right: -20px;
            }
            .account {
                top: 20px;
                .fa {
                    right: 70px;
                }
                a.nav-link {
                    padding: 7px !important;
                    right: 20px;
                    font-size: 12px;
                }
                .accountLogout {
                    a {
                        padding: 0 0 0 0px !important;
                        margin-left: 30px;
                    }
                    .accountSwitch {
                        margin-left: 20px;
                    }
                    .select {
                        display: none;
                    }
                }
                .darkModeToggle {
                    left: 60px;
                    top: 25px;
                    z-index: 1;
                }
            }
        }
        .venueName {
            margin: 0 !important;
        }
        .dishdashboard {
            .nav-tabs {
                a {
                    font-size: 12px !important;
                    padding: 10px;
                }
            }
            #selectVenue {
                .select-dropdown {
                    padding-left: 0px;
                }
                max-width: 280px;
            }
            .modal-body {
                h2 {
                    font-size: 14px;
                    padding-bottom: 30px;
                }
            }
        }
    }
    .managementModalButton {
        p {
            font-size: 14px;
        }
    }
    .venue {
        .address {
            display: none;
        }
        .dropdown-toggle {
            padding-right: 6px;
            padding-left: 0px !important;
        }
        top: 5px;
        position: relative;
        left: 0;
    }
    .Logo {
        top: 10px;
        position: relative;
    }
    .mainDashboard {
        h2 {
            font-size: 12px;
        }
    }
    .managementModalButton {
        padding-bottom: 20px;
        margin-bottom: 20px;
        width: 60%;
        min-height: 120px;
        padding-bottom: 0px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .managementModal {
        .row {
            padding-bottom: 0px;
        }
    }
    .form {
        .modal-title {
            p {
                padding: 0px;
            }
        }
    }
    .warning-light {
        position: relative;
        display: inline-block;
        top: 2px;
    }
    .success-light {
        position: relative;
        display: inline-block;
        top: 2px;
    }
    .summaryCards {
        display: none;
    }
    .tooltipSummary {
        display: inline-block;
        position: relative;
        left: 10px;
    }
    .col-xs-4 {
        width: 33.3% !important;
    }
    .quicklink {
        width: auto;
    }
    .quicklinks {
        p {
            font-size: 12px;
        }
    }
    .allrecentresidentsExpanded .recentResidents {
        overflow: hidden;
    }
    .recentResidents {
        max-height: 322px;
        overflow: hidden;
        display: block !important;
    }
    .upcomingExpirations {
        max-height: 322px;
        overflow: hidden;
    }
    .expandCollapseExpirations {
        position: absolute;
        right: 20px;
        top: 40px;
        top: 15px;
        font-size: 12px;
    }
    label.black-text {
        position: initial !important;
    }
    a.map {
        width: 96%;
        height: 250px;
        left: 9px;
        position: relative;
    }
    .envelopeIcon {
        left: 64px;
        top: -64px;
    }
    .expandCollapse {
        top: 15px;
        font-size: 12px;
    }
    .faqModal {
        .input {
            width: 100%;
            margin-bottom: 20px;
            input {
                font-size: 12px !important;
            }
        }
    }
}
@media screen and (max-width: 580px) {
    .accountSettingStep {
        transform: translate(10%, 150px) !important;
    }
    .accountSwitchStep {
        transform: translate(10%, 240px) !important;
    }
}
/*Toggle*/
.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}
.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}
.react-toggle-track {
    width: 50px;
    height: 22px;
    padding: 0;
    border-radius: 30px;
    background-color: var(--primary-color);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}
.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -2px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}
.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}
.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 20px;
    height: 20px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19ab27;
}
.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
    -moz-box-shadow: 0px 0px 3px 2px #0099e0;
    box-shadow: 0px 0px 2px 3px #0099e0;
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
    -moz-box-shadow: 0px 0px 5px 5px #0099e0;
    box-shadow: 0px 0px 5px 5px #0099e0;
}
@media screen and (max-width: 1307px) {
    .keyInformation {
        .guestData {
            max-height: 226px;
        }
    }
}
@media screen and (max-width: 1200px) {
    .keyInformation {
        .guestData {
            max-height: inherit;
        }
    }
}
@media screen and (max-width: 1024px) {
    .notifications {
        width: 96%;
    }
}
@media screen and (max-width: 992px) {
    .notifications {
        width: 94%;
    }
}
@media all and (max-width: 991px) {
    .Login {
        min-height: 600px;
    }
    .Login .Logo {
        display: none;
    }
    .logoMobile {
        text-align: center;
        display: block;
        .redBanner {
            p {
                color: #fff;
                font-size: 20px;
            }
            a {
                color: #fff;
            }
        }
    }
    .logoMobile img {
        padding-bottom: 20px;
        max-width: 200px;
    }
    .redBanner {
        background: #f01446;
        margin-bottom: 30px;
    }
    .Login {
        padding-top: 80px !important;
        background-position: top !important;
        padding: 60px 0px;
    }
    .loginForm h2 {
        display: none;
    }
    .Login .loginForm button {
        width: 100%;
    }
    .loginForm {
        background: #000010;
    }
    .bottom-banner {
        display: none;
    }
}
@media all and (max-width: 576px) {
    .Login .form-check {
        float: left;
        padding-left: 0rem;
    }
    .AUPTitle .Logo {
        display: none;
    }
    .Login form {
        margin: none !important;
    }
    .CreateAccount,
    .FindDeals,
    .SelectPlan {
        .FormContainer {
            padding: 15px;
        }
    }
    .footerCreateAccount {
        padding: 15px;
        height: auto;
    }
}
@media all and (max-width: 355px) {
    .Login {
        min-height: 700px;
    }
}
@media screen and (max-width: 767px) {
    .Login {
        .loginFormContainer,
        .FormContainer {
            padding: 15px 15px;
        }
        #venueManagement {
            #selectVenue {
                .select-dropdown {
                    padding-left: 0px;
                }
            }
        }
    }

    .subscriberNavbar {
        .account {
            .navbar {
                padding-top: 10px;
                .navbar-nav {
                    align-items: baseline;
                    padding-top: 10px;
                }
            }
            .colorMode {
                top: -15px;
            }
            padding-right: 0px;
            top: 25px;
            .accountLogout {
                padding-right: 0px;
                .dropdown {
                    a {
                        margin-left: 8px;
                    }
                }
                a {
                    padding: 0px !important;
                }
            }
            .accountSwitch {
                margin-left: 20px !important;
            }
            .select {
                right: 17px;
            }
        }
        .Logo {
            top: 0px;
        }
        .networkName {
            display: none;
            h1 {
                font-size: 16px;
            }
        }
        .welcomeMessage {
            display: none;
        }
        .dropdown {
            width: 100%;
            position: relative;
            right: 0 !important;
        }
        li {
            width: 100%;
            position: relative;
            a {
                right: 0 !important;
            }
        }
        min-height: 105px;
    }
     .dashboardCard{
      padding-top: 10px;
     }
    .notifications {
        width: 88%;
    }
    .keyInformation {
        margin-bottom: 20px;
    }
    .form {
        .modal-title {
            h4 {
                float: none;
            }
        }
    }
    .modal-title {
        .addProfile {
            padding-left: 0px;
        }
    }
    .fa-download {
        margin-top: 4px;
        margin-left: 0px;
    }
    .formControls {
        .fa-copy {
            margin-top: 4px;
            margin-left: 0px;
        }
        .fa-envelope {
            margin-top: 4px;
            margin-left: 0px;
        }
    }
    .submitButton {
        padding-top: 10px;
        button,
        a {
            width: 100%;
            margin-bottom: 10px;
            margin: 0px auto 10px;
        }
    }
    .modal-fluid.modal-dialog.form {
        width: 95%;
    }
    .deviceCardBody1 {
        padding: 1.25rem 2rem;
    }
    .modal-body {
        .keyInformation {
            .fa-download {
                margin-left: 0px;
            }
            .fa-copy {
                margin-left: 0px;
            }
            .fa-envelope {
                margin-left: 0px;
            }
            .fa-edit {
                margin-left: 0px;
            }
        }
    }
    .CreateAccount,
    .FindDeals {
        padding-top: 80px !important;
        background-position: top !important;
        padding: 20px 0px !important;
        button {
            width: 100%;
        }
    }
    .btn-danger {
        width: 100%;
    }
    #venueManagement .steps-form-2 .steps-row-2 .steps-step-2,
    #venueManagement .steps-form .steps-row .steps-step,
    #networkManagement .steps-form-2 .steps-row-2 .steps-step-2,
    #networkManagement .steps-form .steps-row .steps-step {
        min-width: 30px;
        p {
            display: none;
        }
        &.stepActive {
            min-width: 60px;
            p {
                display: block;
                word-wrap: break-word;
            }
        }
    }
}
